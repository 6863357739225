import { Bandage, MoonZzz, Universal } from '@calm-web/icons';

import messages from './messages';
import { ValueProp } from './types';

export const items: ValueProp[] = [
	{
		icon: Bandage,
		heading: messages.item1Heading,
		copy: messages.item1Copy,
		href: '/stress-anxiety',
	},
	{
		icon: MoonZzz,
		heading: messages.item2Heading,
		copy: messages.item2Copy,
		href: '/sleep',
	},
	{
		icon: Universal,
		heading: messages.item3Heading,
		copy: messages.item3Copy,
		href: '/mindfulness',
	},
];
