import styled from 'styled-components';
import { palette, theme } from 'styled-tools';

import {
	FontSizes,
	FontWeights,
	Text,
	TextButton,
	fontSize,
	fontWeight,
	minWidth,
} from '@calm-web/design-system';

export const Wrapper = styled.section`
	margin: 0 auto;
	max-width: 1440px;
	padding: 0 20px 60px;
	text-align: center;

	${minWidth('desktop')} {
		padding: 0 136px 100px;
	}
`;
export const Heading = styled(Text).attrs({
	el: 'h2',
	color: 'bannerText',
	noMargin: true,
})`
	&&& {
		font-size: 24px;
		margin-bottom: 32px;

		${minWidth('desktop')} {
			font-size: 36px;
			margin-bottom: 40px;
		}
	}
`;
export const Items = styled.ul`
	list-style: none;
	text-align: center;
	padding: 0;

	${minWidth('desktop')} {
		text-align: left;
		display: grid;
		grid-gap: 48px;
		grid-template-columns: 1fr 1fr 1fr;
	}
`;
export const Item = styled.li`
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	padding: 0;

	${minWidth('desktop')} {
		margin-bottom: 0;
	}
`;
export const Icon = styled.div`
	border-radius: 50%;
	height: 54px;
	margin-bottom: 12px;
	width: 54px;

	${minWidth('desktop')} {
		margin-bottom: 20px;
	}
`;
export const ItemHeading = styled(Text).attrs({
	color: 'blackAlpha80',
	el: 'h3',
	weight: FontWeights.Medium,
})`
	&&& {
		font-size: 24px;
		margin-bottom: 8px;

		${minWidth('desktop')} {
			font-size: 31.5px;
		}
	}
`;
export const ItemCopy = styled(Text).attrs({
	el: 'p',
	color: 'blackAlpha80',
	size: FontSizes.base,
})`
	&&& {
		margin-bottom: 12px;

		${minWidth('desktop')} {
			margin-bottom: 20px;
		}
	}
`;
export const Cta = styled.span`
	color: ${palette('blackAlpha80')};
	display: inline-block;
	${fontSize(FontSizes.base)}
	${fontWeight(FontWeights.Medium)}
	margin-top: auto;
	text-decoration: underline;
`;
export const ItemLink = styled(TextButton).attrs({ hideUnderline: true })`
	display: block;
	position: relative;
	text-decoration: none;
	text-align: left;
	${fontWeight(FontWeights.Regular)}

	&:hover {
		${ItemHeading}, ${Cta} {
			color: ${palette('headerBlue')};
		}
	}

	&:after {
		border: 2px ${palette('headerBlue')} solid;
		border-radius: 20px;
		content: '';
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(1);
		transition: ${theme('animation.transition')};
	}

	&:focus {
		outline: none;
		&:after {
			opacity: 1;
			transform: scale(1.05);
		}
	}
`;
export const Banner = styled.div`
	height: 300px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	z-index: 0;

	${minWidth('desktop')} {
		height: 500px;
		margin-bottom: 100px;
	}

	&:after {
		background: linear-gradient(
			180deg,
			#f7f7f7 0%,
			rgba(255, 255, 255, 0) 25%,
			rgba(255, 255, 255, 0) 75%,
			#f7f7f7 100%
		);
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
`;
