import styled from 'styled-components';
import { palette } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

export const Wrapper = styled.main`
	background: ${palette('gray0')};
	margin-top: -64px;
	position: relative;

	${minWidth('desktop')} {
		margin-top: -100px;
	}
`;
export const GradientBg = styled.div`
	background: linear-gradient(180deg, rgba(226, 234, 255, 0) 0%, #e2eaff 100%);
`;
