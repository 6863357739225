import { useIntl } from 'react-intl';

import Image from '@/components/image/NextImage';
import { useAnalytics } from '@/hooks/analytics';
import { useRouterPush } from '@/hooks/utils/useRouterPush';

import { items } from './data';
import messages from './messages';
import { Wrapper, Heading, Items, Item, ItemLink, Icon, ItemHeading, ItemCopy, Cta, Banner } from './styles';
import { ValueProp } from './types';

const ValueProps = ({ noLink = false, onClickEvent }: { noLink?: boolean; onClickEvent?: () => void }) => {
	const routerPush = useRouterPush();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();

	const onClick = async (item: ValueProp) => {
		logEvent({
			eventName: 'Homepage : CTA : Clicked',
			eventProps: {
				section: 'value_props',
				href: item.href,
				name: formatMessage(item.heading),
				event_location: 'value_props',
			},
		});
		if (onClickEvent) {
			return onClickEvent();
		}
		await routerPush(item.href);
	};

	return (
		<>
			<Wrapper>
				<Heading>{formatMessage(messages.heading)}</Heading>
				<Items>
					{items.map(item => (
						<Item key={formatMessage(item.heading)}>
							<ItemLink onClick={() => onClick(item)}>
								<Icon as={item.icon} />
								<ItemHeading>{formatMessage(item.heading)}</ItemHeading>
								<ItemCopy>{formatMessage(item.copy)}</ItemCopy>
								{!noLink && <Cta data-testid="learnMoreCTA">{formatMessage(messages.learnMoreCta)}</Cta>}
							</ItemLink>
						</Item>
					))}
				</Items>
			</Wrapper>
			<Banner>
				<Image
					alt=""
					loading="lazy"
					layout="fill"
					objectFit="cover"
					src="/_n/images/homepage/content-tile-collage.webp"
				/>
			</Banner>
		</>
	);
};

export default ValueProps;
