import { defineMessages } from 'react-intl';

export default defineMessages({
	heading: {
		id: 'homepage.valueProps.heading',
		defaultMessage: "We're here to help you feel better.",
		description: 'Heading for the value props section',
	},
	item1Heading: {
		id: 'homepage.valueProps.item1.heading',
		defaultMessage: 'Stress less.',
		description: 'Heading for value prop',
	},
	item1Copy: {
		id: 'homepage.valueProps.item1.copy',
		defaultMessage: 'Get in-the-moment relief for stress and anxiety so you can get back to living.',
		description: 'Copy for value prop',
	},
	item2Heading: {
		id: 'homepage.valueProps.item2.heading',
		defaultMessage: 'Sleep more.',
		description: 'Heading for value prop',
	},
	item2Copy: {
		id: 'homepage.valueProps.item2.copy',
		defaultMessage: 'Fall asleep (and stay asleep) naturally and peacefully.',
		description: 'Copy for value prop',
	},
	item3Heading: {
		id: 'homepage.valueProps.item3.heading',
		defaultMessage: 'Live mindfully.',
		description: 'Heading for value prop',
	},
	item3Copy: {
		id: 'homepage.valueProps.item3.copy',
		defaultMessage: "Navigate life's ups and downs with resilience, confidence and guided support.",
		description: 'Copy for value prop',
	},
	learnMoreCta: {
		id: 'homepage.valueProps.learnMoreCta',
		defaultMessage: 'Learn More',
		description: 'Cta that takes user to relevant sleep, stress, and mindfulness page',
	},
});
